import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { v1 as uuid } from 'uuid';

import Radio from './Radio';

const RadioGroup = ({ className, name, options, defaultValue, verticalAlign, onChange }) => {
    const [value, setValue] = useState(null);
    useEffect(() => {
        if (defaultValue !== undefined) setValue(defaultValue);
    }, [defaultValue]);
    const handleChange = (option) => {
        setValue(option.value);
        onChange({ name, value: option.value });
    };

    return (
        <div className={className}>
            {options.map((option) => (
                <Radio key={uuid()} id={uuid()} name={name} label={option.label} verticalAlign={verticalAlign} onChange={() => handleChange(option)} clickable checked={value === option.value} />
            ))}
        </div>
    );
};

RadioGroup.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            default: PropTypes.bool
        })
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    orientation: PropTypes.string.isRequired,
    verticalAlign: PropTypes.string
};

export default styled(RadioGroup)`
    display: ${(props) => (props.orientation === 'horizontal' ? 'flex' : 'block')};

    > ${Radio} {
        margin-bottom: ${(props) => (props.orientation === 'vertical' ? '0.5rem' : 0)};
    }
`;
