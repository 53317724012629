export default {
    CLASSIC: {
        SHORT: 'C',
        LONG: 'Classique'
    },
    PRECARITY: {
        SHORT: 'M',
        LONG: 'Modeste'
    },
    GREAT_PRECARITY: {
        SHORT: 'PE',
        LONG: 'Précarité énergétique'
    }
};
