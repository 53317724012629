import React, { isValidElement, cloneElement, useMemo } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidV1 } from 'uuid';

import styleAutocomplete from './utils/style-autocomplete';
import { getDefaultValue } from './utils';

import { Field } from '../input';
import { AutocompletePopover } from './AutocompletePopover';
import useAutocomplete from './hooks/useAutocomplete';
import useOutsideClick from '../../hooks/useOutsideClick';

const UnStyledAutocomplete = ({ className, fluid, id, name, label, placeholder, defaultValue, disabled, async, loading, options, error, actionView, onChange, onSelect, onHide }) => {
    const { shown, hasFocus, selectedOption, matchingOptions, setShown, handleOptionClick, handleChange, handleBlur, handleFocus } = useAutocomplete(
        async,
        options,
        actionView,
        onChange,
        onSelect,
        handleHide
    );
    useOutsideClick(id, () => {
        setShown(false);
    });
    const defaultId = useMemo(() => id, []);

    function handleActionViewClick() {
        setShown(false);
        actionView.props.onClick();
        handleHide();
    }

    function handleHide() {
        if (onHide) onHide();
    }

    const actionViewClone = isValidElement(actionView) && cloneElement(actionView, { onClick: handleActionViewClick });

    return (
        <div className={className}>
            <Field
                id={defaultId}
                fluid={fluid}
                type='text'
                name={name}
                label={label}
                placeholder={placeholder}
                defaultValue={getDefaultValue(selectedOption, defaultValue, hasFocus)}
                disabled={disabled}
                error={error}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
            {shown && <AutocompletePopover loading={loading} options={matchingOptions} actionView={actionViewClone} onOptionClick={handleOptionClick} />}
        </div>
    );
};

const StyledAutocomplete = styleAutocomplete(UnStyledAutocomplete);

export const Autocomplete = ({ id = uuidV1(), fluid = false, disabled = false, async = true, loading = false, options = [], ...restProps }) => {
    return <StyledAutocomplete id={id} fluid={fluid} disabled={disabled} async={async} loading={loading} options={options} {...restProps} />;
};
Autocomplete.propTypes = {
    fluid: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    async: PropTypes.bool,
    loading: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any
        })
    ),
    error: PropTypes.string,
    actionView: PropTypes.node,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    onHide: PropTypes.func
};
