import React from 'react';
import styled from 'styled-components';
import { v1 as uuid } from 'uuid';
import PropTypes from 'prop-types';

const defaultId = uuid();

function UnStyledTextarea({ className, name, label, onChange }) {
    return (
        <div className={className}>
            {label && <label htmlFor={defaultId}>{label}</label>}
            <textarea
                name={name}
                id={defaultId}
                rows={5}
                cols={50}
                onChange={({ target }) => onChange({ label, name, value: target.value.trim() })}
            />
        </div>
    );
}

const StyledTextarea = styled(UnStyledTextarea)`
    > textarea {
        resize: none;
        width: 100%;
        padding: 0.525rem 0.85rem;
        border: 1px solid var(--color-input-border-default);
        border-radius: 0.25rem;
        color: ${(props) => (props.disabled ? 'var(--color-input-label)' : 'var(--color-black)')};
        transition: all 200ms ease-out;
        -webkit-transition: all 200ms ease-out;
        font-size: var(--input-size);
        min-height: 37.2px;

        ::-webkit-input-placeholder,
        ::placeholder {
            color: var(--color-input-place-holder);
        }

        background: ${(props) => (props.disabled ? 'rgba(215, 215, 215, 0.5)' : 'white')};

        :focus {
            outline: none;
            border-color: var(--color-input-border-focus);
            box-shadow: 0 0 0 1px rgba(178, 209, 255, 0.49);
        }
    }
`;

function Textarea(props) {
    return <StyledTextarea {...props} />;
}

Textarea.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
};

export default Textarea;
