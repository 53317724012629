import React from 'react';
import PropTypes from 'prop-types';

import styleAutocompletePopover from './utils/style-autocomplete-popover';

import { Loader } from '../loader';

const UnStyledAutocompletePopover = ({ className, loading, options, actionView, onOptionClick }) => {
    return <div className={className}>{loading ? <Loader /> : displayOptions(options, actionView, onOptionClick)}</div>;
};

function displayOptions(options, actionView, onOptionClick) {
    return (
        <React.Fragment>
            <ul>
                {options.map((option, index) => (
                    <li key={index} onClick={() => onOptionClick(option)}>
                        {option.label}
                    </li>
                ))}
            </ul>
            {actionView && <footer>{actionView}</footer>}
        </React.Fragment>
    );
}

const StyledAutocompletePopover = styleAutocompletePopover(UnStyledAutocompletePopover);

export const AutocompletePopover = (props) => <StyledAutocompletePopover {...props} />;
AutocompletePopover.propTypes = {
    loading: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired,
    actionView: PropTypes.node,
    onOptionClick: PropTypes.func.isRequired
};
