import React, { isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';

import styleImageSlider from './utils/style-image-slider';
import { isNonEmptyString } from '../../utils';
import { IMAGE_SLIDER_TEST_ID } from '../../utils/tests-ids';

import { Image } from '../image';
import Pagination from '../Pagination';
import useImageSlider from './hooks/useImageSlider';

function UnStyledImageSlider({ className, slides, noDataView, onSlideChange }) {
    const { activeSlideIndex, hasPageChangedOnce, handleSlideChange } = useImageSlider(slides, onSlideChange);

    return (
        <div data-testid={IMAGE_SLIDER_TEST_ID} className={className}>
            {slides.map((slide, slideIndex) => (
                <div key={slideIndex} className={getClassName(slideIndex, activeSlideIndex, hasPageChangedOnce)}>
                    {isNonEmptyString(slide.fileDownloadUrl) ? <Image src={slide.fileDownloadUrl} /> : isValidElement(noDataView) && cloneElement(noDataView, { slide })}
                </div>
            ))}
            <footer>{slides.length > 1 && <Pagination pagesCount={slides.length} onPageChange={handleSlideChange} />}</footer>
        </div>
    );
}

function getClassName(slideIndex, activeSlideIndex, hasPageChangedOnce) {
    return slideIndex === activeSlideIndex ? (hasPageChangedOnce ? 'shown' : 'default') : 'hidden';
}

const StyledImageSlider = styleImageSlider(UnStyledImageSlider);

export function ImageSlider({ slides = [], ...restProps }) {
    return <StyledImageSlider slides={slides} {...restProps} />;
}
ImageSlider.propTypes = {
    slides: PropTypes.arrayOf(
        PropTypes.shape({
            fileDownloadUrl: PropTypes.string
        })
    ),
    noDataView: PropTypes.node,
    onSlideChange: PropTypes.func
};
