import React from 'react';
import styled from 'styled-components';

const Text = ({ testId, id, label, placeholder, name, autoComplete, defaultValue, disabled, required, onChange, onBlur, onFocus }) => {
    return (
        <input
            data-testid={testId}
            id={id}
            type='text'
            placeholder={placeholder}
            name={name}
            disabled={disabled}
            required={required}
            defaultValue={defaultValue}
            autoComplete={autoComplete}
            onChange={({ target }) => onChange({ label, name, value: target.value.trim() })}
            onBlur={({ target }) => onBlur({ label, name, value: target.value.trim() })}
            onFocus={onFocus}
        />
    );
};

export default styled(Text)``;
