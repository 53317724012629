import React from 'react';
import styled from 'styled-components';

const Email = ({ id, label, placeholder, name, disabled, required, autoComplete, onChange, defaultValue }) => {
    return (
        <input
            id={id}
            type='email'
            placeholder={placeholder}
            name={name}
            disabled={disabled}
            required={required}
            defaultValue={defaultValue}
            autoComplete={autoComplete}
            onChange={({ target }) => onChange({ label, name, value: target.value.trim() })}
        />
    );
};

export default styled(Email)``;
