import React from 'react';
import styled from 'styled-components';

function Textarea({ testId, id, label, placeholder, name, disabled, required, autoComplete, onChange, onBlur, defaultValue }) {
    return (
        <textarea
            data-testid={testId}
            id={id}
            data-gramm_editor='false'
            rows={5}
            placeholder={placeholder}
            data-spellcheck={false}
            name={name}
            disabled={disabled}
            required={required}
            defaultValue={defaultValue}
            autoComplete={autoComplete}
            onChange={({ target }) => onChange({ label, name, value: target.value.trim() })}
            onBlur={({ target }) => onBlur({ label, name, value: target.value.trim() })}
        />
    );
}

export default styled(Textarea)``;
