import React from 'react';
import styled from 'styled-components';

import { isValidValue } from '../../utils';

import useCurrentValue from '../input/hooks/useCurrentValue';

function Numeric({ testId, id, label, placeholder, name, defaultValue, disabled, required, min = 0, max, maxHandled, onChange, onBlur }) {
    const { currentValue, currentValueAsText, setCurrentValue, setCurrentValueAsText } = useCurrentValue({ defaultValue, min });

    function handleKeyDown(evt) {
        const numberValidationRegex = /[0-9.,]|\bBackspace\b|\bArrowLeft\b|\bArrowRight\b|\bTab\b/;
        if (!numberValidationRegex.test(evt.key)) evt.preventDefault();

        if (!maxHandled) return;

        preventDoubleComaOrDot(evt);
        const { valueAsNumber, valueAsText } = getCurrentValue(currentValueAsText, evt.key);
        if (isLowerThanMin(valueAsNumber) || isGreaterThanMax(valueAsNumber)) evt.preventDefault();
        else setCurrentValueAsText(valueAsText);
    }

    function preventDoubleComaOrDot(evt) {
        const separators = [',', '.'];
        if (separators.includes(evt.key) && separators.some((separator) => currentValueAsText.includes(separator))) {
            evt.preventDefault();
        }
    }

    function getCurrentValue(value = '', key) {
        const currentValueString = ['ArrowLeft', 'Tab', 'ArrowRight', 'Home'].includes(key)
            ? value
            : key === 'Backspace'
            ? value.length > 0
                ? getUpdatedValueAfterBackSpace(value)
                : value
            : getUpdatedTypedValue(value, key);

        return { valueAsText: currentValueString, valueAsNumber: Number(currentValueString) };
    }

    function isGreaterThanMax(value) {
        return max > 0 && value > max;
    }

    function isLowerThanMin(value) {
        return value < min;
    }

    function handleChange({ target }) {
        const value = getValue(target);
        if (isLowerThanMin(value)) return;
        if (isGreaterThanMax(value)) return;
        if (isValidValue(target)) onChange({ label, name, value: getValue(target) });
    }

    function setCurrentValueOnInput() {
        const input = document.getElementById(id);
        if (input) input.value = currentValue;
    }

    function handleBlur({ target }) {
        const value = getValue(target);

        if (isLowerThanMin(value)) setCurrentValueOnInput();
        else if (isGreaterThanMax(value)) setCurrentValueOnInput();
        else {
            if (isValidValue(target) && value !== currentValue) {
                setCurrentValue(value);
                onBlur({ label, name, value });
            }
        }
    }

    function getValue(target) {
        return isValidValue(target) ? Number(formatValue(target.value)) : 0;
    }

    function formatValue(value) {
        return typeof value === 'string' ? value.replace(/,/g, '.').replace(' ', '') : value;
    }

    function getUpdatedValueAfterBackSpace(prevValue) {
        const cursorStartPosition = getCursorStartPosition();
        const cursorEndPosition = getCursorEndPosition();

        let nextValue = prevValue;
        if (isValidValue(cursorStartPosition) && isValidValue(cursorEndPosition)) {
            if (isUserSelect(cursorStartPosition, cursorEndPosition)) nextValue = '';
            else if (prevValue.length > 0) {
                const prevValueAsArray = prevValue.split('');
                prevValueAsArray.splice(cursorStartPosition - 1, 1);
                nextValue = prevValueAsArray.join('');
            }
        }

        return getValidValue(prevValue, nextValue);
    }

    function getUpdatedTypedValue(prevValue, key) {
        const cursorStartPosition = getCursorStartPosition();
        let nextValue;
        if (isValidValue(cursorStartPosition) && prevValue.length > 0) {
            const beforeCursorValue = prevValue.substring(0, cursorStartPosition);
            const afterCursorValue = prevValue.substring(cursorStartPosition);

            nextValue = beforeCursorValue.concat(key).concat(afterCursorValue).trim();
        } else nextValue = prevValue.concat(key);

        return getValidValue(prevValue, nextValue);
    }

    function getCursorStartPosition() {
        const input = document.getElementById(id);

        return input.selectionStart;
    }

    function getCursorEndPosition() {
        const input = document.getElementById(id);

        return input.selectionEnd;
    }

    function isUserSelect(cursorStartPosition, cursorEndPosition) {
        return cursorStartPosition !== cursorEndPosition;
    }

    function getValidValue(prevValue, nextValue) {
        const formattedNextValue = formatValue(nextValue);

        return nextValue === '' ? '' : Number.isNaN(Number(formattedNextValue)) ? prevValue : formattedNextValue;
    }

    return (
        <input
            data-testid={testId}
            id={id}
            type={getInputType(maxHandled)}
            placeholder={placeholder}
            name={name}
            onWheel={(e) => e.currentTarget.blur()}
            disabled={disabled}
            required={required}
            defaultValue={defaultValue}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            onBlur={handleBlur}
        />
    );
}

function getInputType(maxHandled) {
    return maxHandled ? 'text' : 'number';
}

const UnStyledControlledNumber = ({ onChange, value }) => {
    const onChangeValue = (e) => {
        onChange(parseInt(e.target.value) ? parseInt(e.target.value) : '');
    };
    return <input value={value} type='number' onChange={onChangeValue} />;
};

export const ControlledNumber = styled(UnStyledControlledNumber)``;

export default styled(Numeric)``;
