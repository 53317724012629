import styled from 'styled-components';

export default function (UnStyledTableActions) {
    return styled(UnStyledTableActions)`
        display: flex;
        justify-content: center;
        align-items: center;

        > * {
            margin-right: 0.5rem;

            :last-child {
                margin-right: 0;
            }
        }
    `;
}
